<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="30%">
    <template slot="title">
      <h6 class="dialog-header-title">{{ formatName(customer.name) }}</h6>
      <p>{{ formatDate(billing.date_paid, "dddd ddd, m, y") }}</p>
    </template>
    <el-row type="flex">
      <el-col :md="12">
        <p>Amount</p>
        <span>{{ formatPrice(billing.amount) }}</span>
      </el-col>
      <el-col :md="12" class="is-flex is-flex-column is-align-end">
        <p>Transaction Id</p>
        <span>{{ billing.id }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :md="12">
        <p>Subscription cycle</p>
        <span
          >{{ formatDate(billing.subscription_start_date, "dddd do, m, y") }}
          <i class="el-icon-right"></i>
          {{ formatDate(billing.subscription_end_date, "dddd do, m, y") }}</span
        >
      </el-col>
      <el-col :md="12" class="is-flex is-flex-column is-align-end">
        <p>Status</p>
        <el-tag :type="setType(billing.payment_status)">{{
          formatText(billing.payment_status)
        }}</el-tag>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :md="12">
        <p>Payment type</p>
        <span>{{ formatText(billing.payment_type) }}</span>
      </el-col>
      <el-col :md="12" class="is-flex is-flex-column is-align-end">
        <p>Payment Gateway</p>
        <span>{{ formatText(billing.payment_gateway) }}</span>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "BillingDetails",
  props: {
    show: Boolean,
    billing: Object,
    customer: Object,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

p {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

span {
  font-size: 0.875rem;
  color: #21312a;
}

.el-tag {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
.dialog-header-title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}
</style>
