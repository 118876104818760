<template>
  <div>
    <eden-page-header
      title="Subscriptions / Billing History"
      :subtitle="`${formatName(customer.name)}`"
    />
    <eden-overview-card
      :title="`${
        customer.name ? `${formatName(customer.name)}'s Payment History` : '-'
      }`"
    >
      <template slot="actions">
        <eden-table-actions
          :show-search="false"
          :filter-items.sync="filterItems"
          @filter="getBillingHistoryWithFilters"
        >
          <el-dropdown class="more" trigger="click" @command="setFileFormat">
            <el-button
              type="primary"
              icon="eden-icon--upload"
              :disabled="!pageData.data.length"
              >Export</el-button
            >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="pdf">PDF</el-dropdown-item>
              <el-dropdown-item command="csv">CSV</el-dropdown-item>
              <el-dropdown-item command="excel">Excel</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </eden-table-actions>
      </template>
      <template slot="content">
        <eden-loader
          v-if="fetching || filtering"
          :cols="7"
          type="four-columns-table"
        />
        <template v-else>
          <el-table
            :data="pageData.data"
            empty-text="No Billing History"
            width="100%"
          >
            <el-table-column width="80" prop="id">
              <template slot="header">
                <span class="eden-table-header">
                  ID
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <p class="text-black" @click="command(`view-${scope.row.id}`)">
                  {{ scope.row.id }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="amount" sortable>
              <template slot="header">
                <span class="eden-table-header">
                  Amount
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <p>{{ formatPrice(scope.row.amount) }}</p>
              </template>
            </el-table-column>
            <el-table-column width="240">
              <template slot="header">
                <span class="eden-table-header">
                  Subscription Cycle
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <p>
                  {{ formatDate(scope.row.subscription_start_date, "d-m-y") }}
                  <i class="el-icon-right"></i>
                  {{ formatDate(scope.row.subscription_end_date, "d-m-y") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="payment_type" width="120">
              <template slot="header">
                <span class="eden-table-header">
                  Channel
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <div class="eden-td-stack">
                  <p class="text-black">
                    {{ formatText(scope.row.payment_channel) }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="payment_gateway" width="120">
              <template slot="header">
                <span class="eden-table-header">
                  Gateway
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <div class="eden-td-stack">
                  <p class="text-black">
                    {{ formatText(scope.row.payment_gateway) }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" width="120">
              <template slot="header">
                <span class="eden-table-header">
                  Status
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <el-tag :type="setType(scope.row.payment_status)">{{
                  formatText(scope.row.payment_status)
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="paymentDate">
              <template slot="header">
                <span class="eden-table-header">
                  Payment Date
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <p>{{ formatDate(scope.row.date_paid, "do m, y") }}</p>
              </template>
            </el-table-column>
            <el-table-column width="60">
              <template slot-scope="scope">
                <el-dropdown @command="command" class="more">
                  <span class="el-dropdown-link">
                    <i class="eden-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="eden-icon-eye"
                      :command="{ action: 'view', index: scope.$index }"
                      >View details</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            v-if="pageData.data.length && !hidePagination"
            :from="pageData.from"
            :to="pageData.to"
            :total="pageData.total"
            :current-page.sync="page"
          />
        </template>
      </template>
    </eden-overview-card>
    <billing-details
      :show.sync="showBillingDetails"
      :billing="billingDetails"
      :customer="customer"
    />
    <!-- <customer-billing-history-export
      :show.sync="showCustomerBillingHistoryExport"
      :file-format="fileFormat"
      :customer="customer"
    /> -->
  </div>
</template>

<script>
import BillingDetails from "@/components/Subscriptions/SubscriptionsPayment/BillingDetails";
// import CustomerBillingHistoryExport from "../../components/Customers/Customer/Billings/CustomerBillingHistoryExport";

import * as actions from "@/store/action-types";
import * as mutations from "@/store/mutation-types";
// import billings from "@/requests/subscriptions/billings";

export default {
  name: "CustomerSubscriptions",
  components: {
    BillingDetails,
    // CustomerBillingHistoryExport
  },
  data() {
    return {
      fetching: false,
      filtering: false,
      page: 1,
      pageData: {
        from: null,
        to: null,
        total: null,
        data: [],
      },
      customer: {},
      showBillingDetails: false,
      showCustomerBillingHistoryExport: false,
      fileFormat: "",
      billingDetails: {},
      filterItems: {
        amount: {
          label: "Amount",
          type: "select",
          options: [
            "All",
            "0 - 20000",
            "20001 - 40000",
            "40001 - 60001",
            "60001 - 80000",
            "80001 - 100000",
            "100001 - 120000",
            "120001 - 140000",
          ],
          value: "all",
        },
        billingStatus: {
          label: "Status",
          type: "radio",
          options: ["All", "Successful", "Pending", "Failed"],
          value: "all",
        },
      },
    };
  },
  watch: {
    page() {
      if (!this.$store.getters.billing_history[this.page]) {
        this.getBillingHistory();
      } else {
        this.setCurrentPageData();
      }
    },
  },
  created() {
    if (!this.$store.getters.billing_history[this.page]) {
      this.getBillingHistory();
    } else {
      this.setCurrentPageData();
    }
  },
  methods: {
    setFileFormat(format) {
      this.fileFormat = format;
      this.showCustomerBillingHistoryExport = true;
    },
    command(command) {
      if (command.action === "view") {
        this.billingDetails = { ...this.pageData.data[command.index] };
        this.showBillingDetails = true;
      } else {
        //
      }
    },
    setCurrentPageData() {
      this.pageData = { ...this.$store.getters.billing_history[this.page] };
      this.hidePagination = false;
    },
    getBillingHistoryWithFilters() {
      // const payload = {
      //   amount: this.filterItems.amount.value,
      //   billing_status: this.filterItems.billingStatus.value,
      //   customer_email: this.customer.email
      // };
      this.filtering = true;
      // billings
      //   .filter(payload)
      //   .then(response => {
      //     this.pageData.data = response.data.data;
      //     this.hidePagination = true;
      //     this.filtering = false;
      //   })
      //   .catch(() => {
      //     this.filtering = false;
      //     this.$message.error("Unable to get billing history at the moment!");
      //   });
    },
    getBillingHistory() {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_BILLING_HISTORY, {
          id: this.$route.params.id,
          page: this.page,
        })
        .then((response) => {
          this.customer = { ...response.data.customer };
          this.setCurrentPageData();
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(mutations.BILLING_HISTORY, {
      page: "all",
      billing_history: {},
      clear: true,
    });
    next();
  },
};
</script>

<style scoped lang="scss"></style>
